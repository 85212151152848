// import App from '@/App.vue';
// import RoloPage from '@/pages/RoloPage.vue';
import MainComponent from '@/components/Main/MainComponent.vue';
import NotFound from '@/components/Main/NotFound/NotFound.vue';
import { createRouter, createWebHistory } from 'vue-router';
// import store from "@/store/index"


const routes = [
    {
        path: '/',
        component: MainComponent,
        props:{
            izd: 'rolo',
            // title: store.getters.siteTitle
        }

    },
    {
        path: '/rolo',
        component: MainComponent,
        props:{
            izd: 'rolo',
            // title: store.getters.siteTitle
        }
        
    },
    {
        path: '/wood',
        component: MainComponent,
        props:{
            izd: 'wood',
            // title: store.getters.siteTitle
        }
        
    },
    {
        path: '/gform',
        component: MainComponent,
        props:{
            izd: 'gform',
            // title: store.getters.siteTitle
        }
        
    },
    {
        path: '/plisse',
        component: MainComponent,
        props:{
            izd: 'plisse',
            // title: store.getters.siteTitle
        }
        
    },
    {
        path: '/vform',
        component: MainComponent,
        props:{
            izd: 'vform',
            // title: store.getters.siteTitle
        }
        
    },
    {
        path: '/roma',
        component: MainComponent,
        props:{
            izd: 'roma',
            // title: store.getters.siteTitle
        }
        
    },

    { path: '/:pathMatch(.*)*', component: NotFound },
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router