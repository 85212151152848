<template>
  <footer v-if="footerInfo" class="footer">
    <wrapper-component :classes="'footer__container'">
      <div class="footer__left-side">
        <ul class="footer__list">
<!--            <li class="footer__list-item">-->
<!--                <h2 class="footer__mini-title footer__title-icon footer__title-icon_rek">-->
<!--                    Реквизиты компании-производителя и правообладателя тм FOROOM-->
<!--                </h2>-->
<!--                <span class="footer__text">-->
<!--                    <p>Производитель торговой марки FOROOM – компания ООО «Фирма «КАЛИТА».</p>-->

<!--                    <p>Юридический адрес: Московская область, Раменский городской округ, село Михайловская Слобода, улица Шоссейная, строение 55</p>-->

<!--                    <p>ИНН 7724024023, КПП 502701001, ОГРН 1027700189604</p>-->
<!--                </span>-->
<!--            </li>-->
<!--            <li class="footer__list-item">-->
<!--                <h2 class="footer__mini-title footer__title-icon footer__title-icon_contacts">-->
<!--                    Контакты-->
<!--                </h2>-->
<!--                <a-->
<!--                    :href="'tel:' + footerInfo.phoneNumber.replace(/\D/g, '')"-->
<!--                    class="footer__text"-->
<!--                >{{ footerInfo.phoneNumber }}</a-->
<!--                >-->
<!--                &lt;!&ndash; <div class="footer__social">-->
<!--                  <a href="https://t.me/SB_Decor" class="footer__social-item footer__social-item_tg"><img src="@/assets/images/footer/social/tg.png" alt="telegram"></a>-->
<!--                <a href="viber://pa?chatURI=sb_decor" class="footer__social-item footer__social-item_viber"><img src="@/assets/images/footer/social/viber.png" alt="telegram"></a>-->
<!--                <a href="https://vk.com/rznforoom" class="footer__social-item footer__social-item_vk"><img src="@/assets/images/footer/social/vk.png" alt="telegram"></a>-->
<!--                <a href="https://ok.ru/rznforoom" class="footer__social-item footer__social-item_ok"><img src="@/assets/images/footer/social/ok.png" alt="telegram"></a>-->
<!--                </div> &ndash;&gt;-->
<!--                <br />-->
<!--                <span class="footer__text"-->
<!--                >Адрес: г.{{ footerInfo.city }}, {{ footerInfo.address }}</span-->
<!--                >-->
<!--            </li>-->
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_rek">
                Реквизиты компании
            </h2>
            <p class="footer__text">
              {{ footerInfo.requisites }}
            </p>
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_contacts">
              Контакты
            </h2>
            <a
              :href="'tel:' + footerInfo.phoneNumber.replace(/\D/g, '')"
              class="footer__text"
              >{{ footerInfo.phoneNumber }}</a
            >
            <!-- <div class="footer__social">
              <a href="https://t.me/SB_Decor" class="footer__social-item footer__social-item_tg"><img src="@/assets/images/footer/social/tg.png" alt="telegram"></a>
            <a href="viber://pa?chatURI=sb_decor" class="footer__social-item footer__social-item_viber"><img src="@/assets/images/footer/social/viber.png" alt="telegram"></a>
            <a href="https://vk.com/rznforoom" class="footer__social-item footer__social-item_vk"><img src="@/assets/images/footer/social/vk.png" alt="telegram"></a>
            <a href="https://ok.ru/rznforoom" class="footer__social-item footer__social-item_ok"><img src="@/assets/images/footer/social/ok.png" alt="telegram"></a>
            </div> -->
            <br />
            <span class="footer__text"
              >Адрес: г.{{ footerInfo.city }}, {{ footerInfo.address }}</span
            >
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title">Координаты</h2>
            <p class="footer__text">
              {{ footerInfo.coordinates.join(", ") }}
            </p>
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_graphik">
              График работы
            </h2>
            <p class="footer__text">
              {{ footerInfo.graphicPNPT }} {{ footerInfo.graphicSB }}
              {{ footerInfo.graphicVSK }}
            </p>
          </li>
        </ul>
        <div v-if="fullDesktop" class="footer__bottom-side">
          <h2 class="footer__mini-title footer__bottom-title">
            Сайт производителя систем foroom
          </h2>
        </div>
      </div>
      <div class="footer__right-side">
        <h2 class="footer__mini-title">Схема проезда</h2>
        <div class="map" id="map"></div>
      </div>
      <div v-if="!fullDesktop" class="footer__bottom-side">
        <h2 class="footer__mini-title footer__bottom-title">
          Сайт производителя систем foroom
        </h2>
      </div>
    </wrapper-component>
  </footer>
</template>

<script>
import WrapperComponent from "../Wrappers/WrapperComponent.vue";
import ymaps from "ymaps";
import "./footer.css";
// import { footerInfo } from "@/store/modules/Footer/footerInfo";
export default {
  name: "FooterComponent",
  props: ["footerInfo"],
  components: { WrapperComponent },
  // data(){
  //   return{

  //   }
  // },
  mounted() {
    this.loadMap();
  },

  watch: {
    footerInfo() {
      this.loadMap();
    },
  },

  methods: {
    loadMap() {
      const footerInfo = this.footerInfo;
      ymaps
        .load("https://api-maps.yandex.ru/2.1/?lang=ru_Ru")
        .then((maps) => {
          // const { YMapMarker} = ymaps;
          const map = new maps.Map("map", {
            center: footerInfo.coordinates,
            zoom: 15,
            lang: "ru_RU",
          });

          const placemark = new maps.Placemark(
            footerInfo.coordinates,
            {
              iconContent: `<img width='35' style="margin-left: -5px; margin-top: -6px;" src="${require(`@/assets/images/foroom_marker.svg`)}"/>`,
              hintContent: footerInfo.companyName,
            },
            {
              // Disabling the replacement of the conventional balloon with the balloon panel.
              balloonPanelMaxMapArea: 0,
              draggable: "true",
              preset: "islands#darkOrangeCircleIcon",
              openEmptyBalloon: true,
            }
          );

          placemark.events.add("balloonopen", function () {
            placemark.properties.set(
              "balloonContent",
              `<div class="balloon">
                <div class="balloon__body">
                <p class="shop-type"><b>${footerInfo.companyName}</b></p>
                <p class="shop-type">Представитель FOROOM</p>
                <p><span class="d-inline-block">Город:</span> ${footerInfo.city}</p>
                <p><span class="d-inline-block">Адрес:</span>${footerInfo.address}</p>
                <p><span>Телефон:</span> ${footerInfo.phoneNumber}</p>
                <p>
                  <span>Время работы:</span>
                  ${footerInfo.graphicPNPT},
                 <br>
                  ${footerInfo.graphicSB}
                  <br>
                  ${footerInfo.graphicVSK}
                </p>
              </div>
              <div class="col-4">
                <div class="balloon__img-container" id="shopImg" data-img="${footerInfo.shopImg}">
                  <img class="balloon__img" src="https://foroom.ru/img/images/map_items/${footerInfo.shopImg}"data-img="${footerInfo.shopImg}">
                </div>
              </div>
          </div>`
            );
          });

          map.geoObjects.add(placemark);
        })
        .catch((error) => console.log("Failed to load Yandex Maps", error));
    },
  },

  computed: {
    fullDesktop() {
      return this.$store.getters.fullDesktop;
    },
    // footerInfo() {
    //   return this.$store.getters.footerInfo;
    // },
  },
};
</script>

<style>
.map {
  width: 100%;
  max-width: 700px;
  height: 400px;
}
.balloon {
  display: flex;
  padding: 10px;
  width: 400px;
  height: 250px;
}

.balloon p {
  margin-right: 15px;
  margin-bottom: 5px;
  color: #3b3a49;
  font-style: normal;
  /* font-weight: 300; */
  letter-spacing: 0.2px;
}

.balloon img {
  width: 120px;
  height: 90px;
}

/*@media screen and (min-width: 1025px) {*/
/*    .footer__right-side .footer__mini-title {*/
/*        margin-top: 262px*/
/*    }*/
/*}*/
</style>
