<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content}` : `Купить шторы недорого!`
    }}</template>
  </metainfo>
  <HeaderComponent :headerInfo="siteInfo?.headerInfo" />
  <main :class="izd">
    <SliderComponent
      :title="titles.hero[izd]"
      :izd="izd"
      :className="'slider-component__title'"
      :sliderList="heroSliderList ? heroSliderList[izd] : null"
    />
    <FormComponent
      :formsInfo="formsInfo"
      :title="formsInfo?.firstFormTitle"
      :izd="izd"
      :type="'call'"
    />
    <HowWeWorkComponent :howWeWorkInfo="siteInfo?.howWeWorkInfo" />
    <AdvantagesComponent :advantagesInfo="siteInfo?.advantagesInfo" />
    <FormComponent
      :formsInfo="formsInfo"
      :title="formsInfo?.secondFormTitle"
      :izd="izd"
      :type="'measure'"
    />
    <PriceComponent v-if="siteInfo" :izd="izd" :formsInfo="formsInfo" />
    <FormComponent
      :formsInfo="formsInfo"
      :title="formsInfo?.thirdFormTitle"
      :izd="izd"
      :type="'calculation'"
    />
    <AboutUsComponent :aboutUsInfo="siteInfo?.aboutUsInfo" />
    <SliderComponent
      v-if="ourWorkSliderList"
      :title="titles.ourWork"
      :izd="'ourWork'"
      :sliderList="ourWorkSliderList"
    />
    <AccordeonComponent :faqInfo="siteInfo?.faqInfo" />
    <ReviewsComponent :reviewsInfo="siteInfo?.reviewsInfo" />
    <FormComponent
      :formsInfo="formsInfo"
      :title="formsInfo?.fourthFormTitle"
      :type="'call'"
      :izd="izd"
    />
  </main>
  <FooterComponent :footerInfo="footerInfo" />
  <wrapper-component v-if="!siteInfo" :classes="'loader-wrapper'">
    <LoaderComponent :isLoader="true" />
    <h2>Пожалуйста подождите...</h2>
  </wrapper-component>
</template>

<script>
import { useMeta } from "vue-meta";
import proxyApiSender from "@/utils/proxyApiSender";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import SliderComponent from "./Slider/SliderComponent.vue";
import FormComponent from "./Form/FormComponent.vue";
import HowWeWorkComponent from "./HowWeWork/HowWeWorkComponent.vue";
import AdvantagesComponent from "./Advantages/AdvantagesComponent.vue";
import PriceComponent from "./Price/PriceComponent.vue";
import AboutUsComponent from "./AboutUs/AboutUsComponent.vue";
import AccordeonComponent from "./Accordeon/AccordeonComponent.vue";
import ReviewsComponent from "./Reviews/ReviewsComponent.vue";
import router from "@/router";
import LoaderComponent from "@/components/UI/LoaderComponent/LoaderComponent.vue";

const TEST_SUBDOMAIN = "sbdecor";

export default {
  name: "MainComponent",
  components: {
    HeaderComponent,
    WrapperComponent,
    SliderComponent,
    FormComponent,
    HowWeWorkComponent,
    AdvantagesComponent,
    PriceComponent,
    AboutUsComponent,
    AccordeonComponent,
    ReviewsComponent,
    FooterComponent,
    LoaderComponent,
  },
  props: ["izd"],

  data() {
    return {
      titles: {
        hero: {
          gform: "Горизонтальные жалюзи",
          rolo: "Рулонные шторы",
          roma: "Римские шторы",
          plisse: "Шторы Плиссе",
          vform: "Вертикальные жалюзи",
          wood: "Деревянные жалюзи",
        },
        ourWork: "Наши работы",
      },
      siteInfo: null,
      subdomain:
        process.env.NODE_ENV === "production"
          ? window.location.host.split(".")[0]
          : TEST_SUBDOMAIN,
    };
  },

  setup() {
    useMeta({
      title: "Салон СБ Декор",
      htmlAttrs: { lang: "en", amp: true },
    });
  },

  async created() {
    const isData = getCookie("data");
    // const resp = JSON.parse(localStorage.getItem("siteData"));

    // let data = null;
    if (isData) {
      let resp = JSON.parse(localStorage.getItem("siteData"));
      this.siteInfo = resp;
    } else {
      const resp = await proxyApiSender("landingApi", "getLandingInfo", {
        subdomain: this.subdomain,
      });
      if (resp.status == "OK") {
        if (process.env.NODE_ENV === "production") {
          let d = new Date();
          d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie = "data=" + true + ";" + expires + ";path=/";
        }

        localStorage.setItem("siteData", JSON.stringify(resp.data));
        this.siteInfo = resp.data;
      } else {
        localStorage.setItem("siteData", "error");
      }
    }

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    }
  },

  beforeMount() {
    this.$store.commit("setSubdomain", this.subdomain);
  },

  mounted() {
    this.$store.dispatch("counter");
    if (!this.izd) {
      router.push("/rolo");
    }
  },
  watch: {
    izd(value) {
      this.$store.dispatch("setIzd", value);
    },
  },
  computed: {
    counter() {
      return this.$store.getters.counter;
    },
    formsInfo() {
      return this.siteInfo?.formsInfo;
    },
    footerInfo() {
      return this.siteInfo?.footerInfo;
    },
    heroSliderList() {
      return this.siteInfo?.sliderInfo?.heroSliderList;
    },
    ourWorkSliderList() {
      return this.siteInfo?.sliderInfo?.ourWorkSliderList;
    },
  },
};
</script>
<style>
.loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  padding-top: 100px;
}
</style>
