<template>
    <div v-if="headerInfo">
        <header class="header">
            <wrapper-component :classes="''">
                <div v-if="!fullDesktop" style="width: 100%">
                    <burger-component :list="headerInfo.dropdownList"/>
                </div>
                <div class="header__container">
                    <a class="header__logo-link" href="#">
                        <img
                            :width="fullDesktop ? 190 : 215"
                            alt=""
                            class="header__logo header__logo_foroom"
                            src="@/assets/images/foroom_logo.png"
                        />
                    </a>
                    <span class="header__mark">{{ headerInfo.oficial_dealer_text }}</span>
                    <div class="user-logo">
                        <img :src="'https://media.foroom.ru/images/landing-content/' + subdomain + '/' + headerInfo.logo" :width="fullDesktop ? headerInfo.logoSizeDesktop : headerInfo.logoSizeMobile " alt="" class="header__logo"/>
                    </div>

                    <div class="header__foroom-block">
                        <span>{{ headerInfo.slogan }}</span>
                    </div>
                    <div class="header__contacts">
                        <div class="header__numbers">
                            <a
                                v-for="item of headerInfo.phoneNumbers"
                                :key="item"
                                :href="'tel:' + item"
                                class="header__number"
                                :class="{ hide: !visible }"
                                @click="sendMetrika"
                            >
                                {{ item }}<span v-if="headerInfo.phoneNumbers.indexOf(item) < headerInfo.phoneNumbers.length - 1">,&nbsp;</span>
                            </a>
                        </div>
                        <a v-if="fullDesktop" class="header__number" href="#">{{
                                headerInfo.dealer_address
                            }}</a>
                    </div>
                </div>
                <div v-if="fullDesktop">
                    <navigation-component
                        :dropdownList="headerInfo.dropdownList"
                        :list="headerInfo.navList"
                    />
                </div>
            </wrapper-component>
        </header>
        <section class="underheader">
            <wrapper-component :classes="'underheader__container'">
        <span class="underheader__mark">
          {{ headerInfo.underheader1 }}
        </span>

                <span class="underheader__mark"
                ><span class="underheader__mark underheader__mark_red"
                >{{ headerInfo.underheader2 }}
          </span></span
                >
            </wrapper-component>
        </section>
    </div>
</template>

<script>
import "./header.css";
import WrapperComponent from "../Wrappers/WrapperComponent.vue";
import BurgerComponent from "./Burger/BurgerComponent.vue";
import NavigationComponent from "./Navigation/NavigationComponent.vue";

export default {
    components: {WrapperComponent, BurgerComponent, NavigationComponent},
    name: "HeaderComponent",
    props: ["headerInfo"],
    data() {
        return {
            visible: false
        }
    },
    methods: {
        sendMetrika() {
            this.visible = true
            window.ym(process.env.VUE_APP_METRIKA_ID, "reachGoal", "clickOnPhone");
        },
    },
    computed: {
        fullDesktop() {
            return this.$store.getters.fullDesktop;
        },
        subdomain() {
            return this.$store.getters.subdomain;
        },
    },
};
</script>

<style scoped>
.header__number {
    position: relative;
}
.header__number.hide::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(147deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 72%);
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
}
</style>
